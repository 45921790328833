<template>
  <div>
    <div class="training-banner"></div>
    <div class="training-data">
      <div>
        <div>
          <span>500,000</span>
          <i>+人</i>
          <p>全平台累计报名学员</p>
        </div>
        <div>
          <span>1,500</span>
          <i>+节</i>
          <p>线上课程</p>
        </div>
        <div>
          <span>700</span>
          <i>+位</i>
          <p>行业实战导师</p>
        </div>
        <div>
          <span>24,000</span>
          <i>+时</i>
          <p>线下课分享总时长</p>
        </div>
      </div>
    </div>
    <div class="training-class">
      <div class="title">
        <h3>课程</h3>
        <i></i>
        <span>COURSE</span>
      </div>
      <div class="training-class-cont">
        <div class="class-list">
          <ul>
            <li v-for="it in classList.list" :key="it.id" @mouseenter="classEvt(it.path,it.cont,it.otherCont)">
              <span>{{it.text}}</span>
              <h5>{{it.title}}</h5>
            </li>
          </ul>
        </div>
        <div :style="{background:'url('+ this.list.path +')'}" class="class-img"></div>
        <div class="class-text">
          <p>{{this.list.cont}}</p>
          <p>{{this.list.otherCont}}</p>
        </div>
      </div>
    </div>
    <div class="training-teacher">
      <div class="title">
        <h3>优秀讲师</h3>
        <i></i>
        <span>EXCELLENT LECTURER</span>
      </div>
      <div class="training-teacher-cont">
        <div>
          <div class="teacher-first">
            <figure></figure>
            <figcaption>
              <h5>景宏伟</h5>
              <span>国家公共营养师、国家高级美容师、外资企业内训师、国家二级人力资源师</span>
              <p>
                职业培训学校资深培训经理，拥有十余年职业培训师从业经验。教学内容多元化，涉及家政、职业技能、企业管理、形象礼仪、公众演讲等。教学风格生动幽默，倡导学以致用，善于调动课堂教学氛围，深受学员们的喜爱。
              </p>
            </figcaption>
          </div>
          <div class="teacher-second">
            <figure></figure>
            <figcaption>
              <h5>邵池璇</h5>
              <span>国家高级育婴培训讲师、高级母婴培训讲师、家政培训讲师</span>
              <p>
                曾任四川省妇联省妇干校“天府妹子”培训班主讲老师。2019年于北京参加首届EBS皇家英式管家培训班并获得英式管家资格。理论知识与教学经验丰富，授课形式多样化，课堂气氛活跃，深受广大学员喜爱。
              </p>
            </figcaption>
          </div>
        </div>
        <div>
          <div class="teacher-third">
            <figure></figure>
            <figcaption>
              <h5>胡正兵</h5>
              <span>高级保洁培训师、高级家电清洗培训师</span>
              <p>
                拥有七年从业经验和五年培训经验，实践经验及理论知识丰富，曾参加苏宁家电清洗学术研究会，并在（成都）国际家庭清洁技术展示训练中心——BEBRITE比博贝尔进修并获得高级保洁培训师证书及高级家电清洗培训师证书。
              </p>
            </figcaption>
          </div>
          <div class="teacher-fourth">
            <figure></figure>
            <figcaption>
              <h5>曾波涛</h5>
              <span>国家高级茶艺师、国家中级评茶员</span>
              <p>
                华南农业大学茶学系专业出身，深耕茶业十余载，具备过硬的专业技能和丰厚的行业经验，授课深入浅出，脉络清晰有条理，善于用通俗易懂的语言和实例讲解专业的茶叶知识。
              </p>
            </figcaption>
          </div>
        </div>
      </div>
    </div>
    <div class="training-process">
      <div class="title">
        <h3>培训流程</h3>
        <i></i>
        <span>TRAINING PROCSS</span>
      </div>
      <div class="training-process-cont"></div>
    </div>
    <div class="training-students">
      <div class="title">
        <h3>学员风采</h3>
        <i></i>
        <span>STUDENT STYLE</span>
      </div>
      <div class="training-students-cont">
        <div>
          <div class="students-first"></div>
          <div class="students-second"></div>
        </div>
        <div>
          <div class="students-third"></div>
          <div class="students-fourth"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      //课程列表
      classList:{
        currentIndex:0,
        list:[
          {
            id:'1',
            title:'管家课程',
            text:'HOUSEKEEPER',
            path:'https://laomaaijia.com/img/training/class1.jpeg',
            cont:'丰富的生活智能与专业素养培养：餐桌礼仪，佳肴名菜菜式制作，名酒鉴赏，水晶银器保养，奢侈品牌的使用及养护，色彩服装搭配，出行计划的制订，团队服务演练、人事组织构架、采购和记账、等相关知识。'
          },
          {
            id:'2',
            title:'保姆课程',
            text:'NANNY',
            path:'https://laomaaijia.com/img/training/class2.jpeg',
            cont:'老马爱家保姆课程包含家庭餐制作、日常保洁、采购记账、洗涤衣物、协助照顾儿童老人(全自理)、宠物护理、家居美化、儿童生活护理(协助)、营养管理、家居收纳、老人生活护理(协助)等内容。'
          },
          {
            id:'3',
            title:'育儿课程',
            text:'PARENTING',
            path:'https://laomaaijia.com/img/training/class3.jpeg',
            cont:'老马爱家为孩子规划快乐成长课程让0-6个月的宝宝在玩耍中感受乐趣，探索发现，激发孩子好奇心与周围世界的兴趣。',
            otherCont:'老马爱家的指导师们会着重指导家长和孩子的亲子互动，并通过镜子时间、体能发育活动、泡泡和降落伞活动等，提升孩子的注意力、培养情绪表达，例如社交性微笑和情绪稳定能力。'
          }
        ]
      },
      list:{
          path:'https://laomaaijia.com/img/training/class1.jpeg',
          cont:'丰富的生活智能与专业素养培养：餐桌礼仪，佳肴名菜菜式制作，名酒鉴赏，水晶银器保养，奢侈品牌的使用及养护，色彩服装搭配，出行计划的制订，团队服务演练、人事组织构架、采购和记账、等相关知识。',
          otherCont:''
      }
    }
  },
  methods:{
    classEvt(path,cont,otherCont){
      this.list.path = path
      this.list.cont = cont
      this.list.otherCont = otherCont
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../style/training.less";
</style>
